import React from 'react';
import { FileText, MessageSquare, BarChart2, Database, Cloud, Lock } from 'lucide-react';
import CryptoJS from 'crypto-js';  // We'll use this for encryption

const TMSBM_BASE_URL = process.env.FOROSTAR_TMSBM || 'http://localhost:9595'

const appData = [
  { 
    id: 1, 
    title: 'MarkParser', 
    description: 'Our powerful trademark application analysis pipeline includes AI driven semantic, phonetic, & visual similarity scoring.', 
    icon: FileText,
    appId: 'markparser',
    serverBPath: TMSBM_BASE_URL  // Add the actual subdomain URL for each app
  },
  // Add more apps here with their respective appIds and serverBPaths
];

const encryptJWT = (jwt, secretKey) => {
  return CryptoJS.AES.encrypt(jwt, secretKey).toString();
};

const handleRedirect = async (appId, serverBPath) => {
  try {
    // Retrieve the JWT from wherever it's stored (e.g., localStorage)
//    const jwt = localStorage.getItem('jwt');
  //  if (!jwt) {
  //    throw new Error('No JWT found');
 //   }

    // Encrypt the JWT
//    const encryptionKey = 'your-secret-encryption-key';  // This should be securely stored and shared with Server B
  //  const encryptedJWT = encryptJWT(jwt, encryptionKey);

    // Create a URL to Server B with the encrypted JWT as a query parameter
    const redirectUrl = new URL(serverBPath);
//    redirectUrl.searchParams.append('token', encodeURIComponent(encryptedJWT));

    // Redirect to Server B
    window.location.href = redirectUrl.toString();
  } catch (err) {
    console.error('Redirect error:', err);
    alert(`Error: ${err.message}. Please log in again.`);
    // Redirect to login page after a short delay
    setTimeout(() => window.location.href = '/login', 3000);
  }
};

const AppCard = ({ title, description, icon: Icon, appId, serverBPath }) => (
  <div 
    onClick={() => handleRedirect(appId, serverBPath)} 
    className="bg-white p-6 rounded-lg border border-blue-300 shadow-lg transition-all duration-300 transform hover:scale-105 hover:shadow-xl cursor-pointer"
  >
    <div className="flex items-center justify-center w-16 h-16 bg-blue-100 text-blue-500 rounded-full mb-4">
      <Icon size={32} />
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const AppDirectory = () => {
  return (
    <div className="container bg-midnight-blue mx-auto px-4 py-16">
      <h1 className="text-3xl text-white font-bold mb-8 text-left">App Directory</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {appData.map((app) => (
          <AppCard key={app.id} {...app} />
        ))}
      </div>
    </div>
  );
};

export default AppDirectory;
