import React from 'react';
import { Menu } from 'lucide-react';
import LoginModal from './LoginModal';

const MainSection = ({
  setIsLoginModalOpen
  }) => {
  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = 40;
      const sectionTop = section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: sectionTop - offset,
        behavior: 'smooth'
      });
    }
  };

  return (
    <section className="container mx-auto px-4 py-16" id="main">
      <h1 className="font-bold mb-4">
        <span className="block text-6xl sm:text-7xl md:text-8xl mb-2">AI,</span>
        <span className="block text-6xl sm:text-7xl md:text-8xl mb-4">Simplified.</span>
      </h1>
      <p className="block text-lg sm:text-xl mb-12">
        Build custom AI microservices and deploy them on production-ready full service containers.
      </p>
      <div className="flex flex-wrap mx-4 gap-4">
        <button

          onClick={() => setIsLoginModalOpen(true)}
          className="bg-gradient-to-r from-blue-900 via-cyan-400 to-green-500 text-white px-8 py-2 rounded-md shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl hover:ring-2 hover:ring-blue-400 hover:ring-opacity-60"
        >
          Login to App Directory
        </button>
        <button
          href="#contact"
          onClick={(e) => scrollToSection(e, 'contact')}
          className="bg-white text-black px-8 py-2 rounded-md shadow-lg shadoe-4x1 transition-transform transform hover:scale-105 hover:shadow-2xl hover:ring-2 hover:ring-blue-400 hover:ring-opacity-50"
        >
          Request An Account
        </button>
      </div>
    </section>
  );
};

export default MainSection;
