import React from 'react';
import { Menu } from 'lucide-react';
import logo from '../logo.ico';
import Applications from './Applications';
import Welcome from './Welcome';

const Header = ({
  deepBlueBackground,
  scrolled,
  menuOpen,
  setMenuOpen,
  isLoggedIn,
  showWelcome,
  username,
  setIsLoginModalOpen,
  handleLogout
}) => {
  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = 40;
      const sectionTop = section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: sectionTop - offset,
        behavior: 'smooth'
      });
    }
  };

  return (
    <header className={`fixed w-full transition-all duration-500 z-40 ${
      deepBlueBackground
        ? 'bg-midnight-blue text-white'
        : scrolled
          ? 'bg-white shadow-md text-gray-800'
          : 'bg-transparent text-gray-800'
    }`}>
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className="flex items-center space-x-2"
        >
          <img src={logo} alt="Logo" className="w-8 h-8" />
          <span className="text-xl font-bold">forostar.ai</span>
        </button>
        <nav className={`md:flex md:space-x-8 ${menuOpen ? 'block' : 'hidden'} absolute md:relative top-full left-0 w-full md:w-auto ${
          deepBlueBackground ? 'bg-black md:bg-transparent' : 'bg-white md:bg-transparent'
        } shadow-md md:shadow-none`}>
          <a href="#doc-processing" onClick={(e) => scrollToSection(e, 'doc-processing')} className="block md:inline-block py-2 px-4 hover:text-gray-300">Services</a>
          <a href="#contact" onClick={(e) => scrollToSection(e, 'contact')} className="block md:inline-block py-2 px-4 hover:text-gray-300">Contact</a>
          {!isLoggedIn ? (
            <button 
              onClick={() => setIsLoginModalOpen(true)} 
              className="block md:inline-block py-2 px-4 hover:text-gray-300 transition duration-300 ease-in-out transform hover:scale-105"
            >
              Login
            </button>
          ) : (
            <button 
              onClick={handleLogout} 
              className="block md:inline-block py-2 px-4 hover:text-gray-300 transition duration-300 ease-in-out transform hover:scale-105"
            >
              Logout
            </button>
          )}
          {isLoggedIn && <Applications isLoggedIn={isLoggedIn} />}
        </nav>
        <button className="md:hidden" onClick={() => setMenuOpen(!menuOpen)}>
          <Menu />
        </button>
      </div>
      {showWelcome && <Welcome username={username} />}
    </header>
  );
};

export default Header;
