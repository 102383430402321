import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8 mt-10">
      <div className="container mx-auto px-4 flex justify-center items-center">
        <p>
          <b>
            &copy; 2024 forostar. All rights reserved. 
            forostar.ai is proudly Canadian.
          </b> {'\uD83C\uDF41'}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
