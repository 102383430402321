import React, { useState, useEffect } from 'react';
import { Menu } from 'lucide-react';
import logo from './logo.ico';
import ContactForm from './components/ContactForm';
import LoginModal from './components/LoginModal';
import Applications from './components/Applications';
import Welcome from './components/Welcome';
import Header from './components/Header';
import MainSection from './components/MainSection';
import ServicesSection from './components/ServicesSection';
import GradientSections from './components/GradientSections';
import Footer from './components/Footer';
import AppDirectory from './components/AppDirectory';

const App = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedService, setSelectedService] = useState('');
  const [deepBlueBackground, setDeepBlueBackground] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [showWelcome, setShowWelcome] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
      const firstSection = document.getElementById('cards');
      if (firstSection) {
        const sectionBottom = firstSection.offsetHeight + 500;
        setDeepBlueBackground(window.scrollY > sectionBottom);
      }
    };
    window.addEventListener('scroll', handleScroll);

    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    const justLoggedIn = localStorage.getItem('justLoggedIn');

    if (token && storedUsername) {
      setIsLoggedIn(true);
      setUsername(storedUsername);
      if (justLoggedIn === 'true') {
        setShowWelcome(true);
        localStorage.removeItem('justLoggedIn');
        setTimeout(() => setShowWelcome(false), 5000);
      }
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleServiceSelect = (service) => {
    setSelectedService(service);
  };

  const handleLoginSuccess = (token, loggedInUsername) => {
    setIsLoggedIn(true);
    setUsername(loggedInUsername);
    localStorage.setItem('token', token);
    localStorage.setItem('username', loggedInUsername);
    localStorage.setItem('justLoggedIn', 'true');
    window.location.reload();
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('justLoggedIn');
    window.location.reload();
  };

  return (
    <div className={`min-h-screen transition-colors duration-500 ${deepBlueBackground ? 'bg-midnight-blue' : 'bg-white'}`}>
      <Header
        deepBlueBackground={deepBlueBackground}
        scrolled={scrolled}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        isLoggedIn={isLoggedIn}
        showWelcome={showWelcome}
        username={username}
        setIsLoginModalOpen={setIsLoginModalOpen}
        handleLogout={handleLogout}
      />
      <main className="pt-24">
        <LoginModal
          isOpen={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
          onLoginSuccess={handleLoginSuccess}
        />
        {isLoggedIn &&
          <AppDirectory />
        }
          <>
            <MainSection setIsLoginModalOpen={setIsLoginModalOpen} />
            <ServicesSection />
            <GradientSections onServiceSelect={handleServiceSelect} />
            <ContactForm initialService={selectedService} />
          </>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default App;
