import React from 'react';

const AppCard = ({ title, description, link }) => (
  <div className="relative bg-gray-200 p-6 rounded-lg border border-gray-300 shadow-lg transition-transform transform hover:bg-white hover:scale-105 hover:shadow-2xl hover:ring-2 hover:ring-blue-600">
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="mb-4">{description}</p>
    <button onClick={() => document.getElementById(link).scrollIntoView({ behavior: 'smooth' })} className="text-blue-600 hover:underline">
      Learn more
    </button>
    <div className="absolute inset-0 border border-gray-300 rounded-lg pointer-events-none"></div>
  </div>
);

const ServicesSection = () => {
  return (
    <section className="container mx-auto mb-14" id="cards">
      <h2 className="text-3xl font-bold mb-8 px-4"></h2>
      <div className="grid md:grid-cols-3 gap-8 px-4">
        <AppCard
          title="Automation"
          description="Process documents and build powerful workflows with cutting-edge OCR and ML driven toolchains."
          link="doc-processing"
        />
        <AppCard
          title="Infrastructure"
          description="Chat storage, model hosting, embeddings and more - all in one place."
          link="ai-containers"
        />
        <AppCard
          title="Enterprise"
          description="Cloud-based and On-Premises solutions for your unique business needs."
          link="enterprise-solutions"
        />
      </div>
    </section>
  );
};

export default ServicesSection;
