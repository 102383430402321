import React from 'react';

const GradientSection = ({ id, title, startColor, endColor, children, onServiceSelect, alignment = 'left' }) => {
  const handleButtonClick = () => {
    onServiceSelect(id);
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
  };
  const alignmentClasses = {
    left: 'text-left',
    right: 'text-right',
  };
  return (
    <section
      id={id}
      className="py-16 pt-24 text-white"
      style={{ background: `linear-gradient(to right, ${startColor}, ${endColor})` }}
    >
      <div className={`container mx-auto px-4 ${alignmentClasses[alignment]}`}>
        <div className={`max-w-2xl ${alignment === 'right' ? 'ml-auto' : ''}`}>
          <h2 className="text-4xl font-bold mb-8">{title}</h2>
          <div className="prose prose-lg prose-invert">
            {React.Children.map(children, child => {
              if (child.type === 'button') {
                return React.cloneElement(child, {
                  onClick: handleButtonClick,
                  className: `${child.props.className} hover:bg-opacity-90 transition-colors mt-4`
                });
              }
              return child;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

const Divider = () => (
  <div className="container mx-auto p-4 my-4 flex justify-center">
    <div className="h-[0.15rem] w-1/2 bg-gradient-to-r from-transparent via-white to-transparent"></div>
  </div>
);

const GradientSections = ({ onServiceSelect }) => {
  return (
    <>
      <Divider />
      <GradientSection
        id="doc-processing"
        className="pt-20"
        title="Automate Documents & Forms"
        startColor="#18031f"
        endColor="#0d0778"
        onServiceSelect={onServiceSelect}
        alignment="left"
      >
        <p className="text-lg mb-2">
          Our cutting-edge OCR and ML-driven tools streamline your document processing workflows. 
          Say goodbye to manual data entry and hello to increased productivity.
        </p>
        <ul className="list-disc list-inside mb-8">
          <li>Intelligent form filling</li>
          <li>Automated data extraction</li>
          <li>Custom workflow creation</li>
          <li>Integration with existing systems</li>
        </ul>
        <button className="bg-white text-blue-700 px-6 py-2 rounded-md hover:bg-gray-100 transition-colors">
          Get Started
        </button>
      </GradientSection>

      <Divider />

      <GradientSection
        id="ai-containers"
        title="Full-Service AI Containers"
        startColor="#11806d"
        endColor="#18031f"
        onServiceSelect={onServiceSelect}
        alignment='right'
      >
        <p className="text-lg mb-8">
          Our 2FS container platform provides a seamless environment for your AI applications. 
          Focus on innovation while we handle the infrastructure. 
          Our unified environment provides connections and storage for:
        </p>
        <ul className="list-disc list-inside mb-8">
          <li>User accounts</li>
          <li>Multi-conversation message history</li>
          <li>Model hosting</li>
          <li>Automated container deployments/scaling</li>
        </ul>
        <button className="bg-white text-blue-700 px-6 py-2 rounded-md hover:bg-gray-100 transition-colors">
          Explore Containers
        </button>
      </GradientSection>

      <Divider />

      <GradientSection
        id="enterprise-solutions"
        title="Custom and Enterprise Solutions"
        startColor="#0d0778"
        endColor="#11806d"
        onServiceSelect={onServiceSelect}
        alignment="left"
      >
        <p className="text-lg mb-8">
          Tailored Chatbots, Document Loaders, Assistants, and more - all designed to meet your unique business needs. 
          From concept to deployment, we're with you every step of the way.
        </p>
        <ul className="list-disc list-inside mb-8">
          <li>Personalized consulting</li>
          <li>Customized AI model development</li>
          <li>On-premises deployment options</li>
          <li>Ongoing support and optimization</li>
        </ul>
        <button className="bg-white text-blue-700 px-6 py-2 rounded-md hover:bg-gray-100 transition-colors">
          Contact Us
        </button>
      </GradientSection>

      <Divider />
    </>
  );
};

export default GradientSections;
