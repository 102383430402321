import React, { useState, useEffect, useRef } from 'react';

const ContactForm = ({ initialService }) => {
  const [formData, setFormData] = useState({
    email: '',
    company: '',
    service: '',
    message: ''
  });
  const [submitStatus, setSubmitStatus] = useState(null);

  const emailInputRef = useRef(null);

  useEffect(() => {
    if (initialService) {
      setFormData(prevState => ({
        ...prevState,
        service: initialService
      }));
      setTimeout(() => {
        emailInputRef.current.focus();
      }, 100);
    }
  }, [initialService]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitStatus('success');
        setFormData({ email: '', company: '', service: '', message: '' });
      } else {
        const errorData = await response.json();
        setSubmitStatus(`error: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error: Network error');
    }
  };

  return (
    <section id="contact" className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h1 className="font-bold mb-4">
          <span className="block text-4xl sm:text-5xl md:text-6xl text-center">Contact Us</span>
        </h1>
        <h2 className="text-1xl mb-12 text-center">We're busy building our services for public access, but you can use this form to request a quote or account.</h2>
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email*</label>
            <input
              ref={emailInputRef}
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="company" className="block text-gray-700 font-bold mb-2">Company Name or Website (optional)</label>
            <input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="service" className="block text-gray-700 font-bold mb-2">Service (optional)</label>
            <select
              id="service"
              name="service"
              value={formData.service}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
            >
              <option value="">Select a service</option>
              <option value="doc-processing">Document Automation</option>
              <option value="ai-containers">Container Services</option>
              <option value="enterprise-solutions">Custom and Enterprise Solutions</option>
            </select>
          </div>
          <div className="mb-6">
            <label htmlFor="message" className="block text-gray-700 font-bold mb-2">Message*</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              rows="4"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
            ></textarea>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-800 transition-colors"
            >
              Send Message
            </button>
          </div>
        </form>
        {submitStatus && (
          <p className={`mt-4 text-center ${submitStatus.startsWith('error') ? 'text-white' : 'text-blue-700'}`}>
            {submitStatus.startsWith('error') ? submitStatus.substring(90) : 'Email received. We will be in touch!'}
          </p>
        )}
      </div>
    </section>
  );
};

export default ContactForm;
