import React from 'react';

const Welcome = ({ username }) => {
  return (
    <div className="absolute top-full left-0 w-full">
      <div className="container mx-auto px-4">
        <div className="text-center text-white bg-indigo-600 py-2 px-4 rounded-b-lg animate-fade-in">
          Welcome back, {username}!
        </div>
      </div>
    </div>
  );
};

export default Welcome;
